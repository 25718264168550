<template>
  <div class="news">
    <Nav nums='3' isColor='#fff'/>
    <div class="placeDetails">
        <div class="bigBox">
            <div class="title">
                {{plantData.name}}
            </div>
            <div class="newsImg">
                <img :src="plantData.images" alt="">
            </div>
            <div class="content" v-html="plantData.content">
                {{plantData.content}}
            </div>
            <div class="baseBtn">
                <router-link to="/cases?active=caseBox">返回</router-link>
            </div>
        </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import Nav from '../nav.vue'
import Floor from '../floor.vue'
import { imgHttp, request } from '../../apis/http'
// import moment from 'moment'
export default {
  components: {
    Nav,
    Floor
  },
  data () {
    return {
        csaeId:'',
        plantData:{}
    }
  },
  created() {
    this.csaeId = this.$route.query.id
  },
  beforeMount() {
      this.plant()
    scrollTo(0, 0)
  },
  methods: {
   async plant () {
      const res = await request({
        url: `api/index/caseinfo?id=${this.csaeId}`
      })
      res.data.data.images = imgHttp + res.data.data.images
    //   console.log(res.data.data)
      this.plantData = res.data.data
   }
 }
}
</script>
<style lang="less" scoped>
@import '../fn.less';
.placeDetails{
    margin-top: 0;
    width: 100%;
    padding: 10.4166vw 18.75vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
            color: #101218;
            font-size: 2.604166vw;
            font-weight: 700;
            line-height: 3.125vw;
        }
        .newsImg{
            margin-top: 6.354166vw;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 37.5vw;
                height: 28.125vw;
            }
        }
        .content{
            margin-top: 5.20833vw;
            color: #57585C;
            font-size: 1.04166vw;
            line-height: 2.08333vw;
        }
        .smallTitle{
            color: #101218;
            font-size: 1.5625vw;
            margin-top: 4.3229166vw;
        }
        .baseBtn{
            margin-top: 7.291666vw;
            display: flex;
            justify-content: space-between;
            color: #101218;
            font-size: 1.04166vw;
            a{
                text-decoration: none;
                color: #101218;
            }
            .paging{
                a{
                    margin-left:2.7604166vw;
                }
            }
        }
}
@media screen and (max-width: 980px) {
  .placeDetails{
    margin-top: 10vw;
    width: 100%;
    padding: 10.4166vw 18.75vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
            color: #101218;
            font-size: 5.604166vw;
            font-weight: 700;
            line-height: 3.125vw;
        }
        .newsImg{
            margin-top: 6.354166vw;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 50.5vw;
                height: 40.125vw;
            }
        }
        .content{
            margin-top: 5.20833vw;
            color: #57585C;
            .vvw(font-size,24);
            line-height: 5.08333vw;
        }
        .smallTitle{
            color: #101218;
            .vvw(font-size,32);
            margin-top: 4.3229166vw;
        }
        .baseBtn{
            margin-top: 7.291666vw;
            display: flex;
            justify-content: space-between;
            color: #101218;
            .vvw(font-size,40);
            a{
                text-decoration: none;
                color: #101218;
            }
            .paging{
                a{
                    margin-left:2.7604166vw;
                }
            }
        }
}
    }
</style>
